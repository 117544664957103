@use 'styles/variables' as var;
@use 'styles/functions' as fn;

/*
TODO - A lot of these styles are "general" and should be further consolidated by declaring the
common styles with a single selector and then more specific ones after

example:

.card, .card-left-aligned, .other-similar {
  ...shared styles
}

.card { ...card only etc };
.card-left-aligned { ...card-left-aligned only etc };
*/
.card,
.card-left-aligned {
  display: flex;
  flex-direction: row;
  margin-bottom: fn.spacing(4.5);

  .card-img {
    width: 70px;
    margin-right: 16px;
  }

  .card-info {
    flex: auto;
    display: flex;
    flex-direction: column;
  }
}

.card-text-subtitle {
  margin-top: 8px;
}

.card-block-aligned {
  .card-img {
    padding: 8px 0;
  }
}

.card-divider {
  display: flex;
  margin-bottom: fn.spacing(4.5);
  flex-direction: column;
  align-items: center;

  .card-text-title {
    text-align: center;
  }

  .card-info {
    flex: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 32px;
    padding-top: 16px;

    .card-text-subtitle {
      margin-top: 16px;
      text-align: center;
    }
  }
}

.card-divider-border {
  padding-top: fn.spacing(4.5);
  border-top: 1px solid #42546b;
}

.card.breakline {
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;

  .card-img {
    margin-right: 0;
  }

  .card-info {
    text-align: center;

    .card-text-title {
      margin-top: fn.spacing(2.5);
    }
  }
}

.card-content {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-width: 100%;
  padding: 16px;
  text-align: center;

  &:last-child {
    padding-bottom: 16px;
  }
}

.image-grid {
  margin-bottom: 16px;
}

.title-typography {
  margin-bottom: 16px;
  text-align: center;
}

@media only screen and (min-width: 1024px) {
  .card-left-aligned {
    display: block;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 0;

    .card-img {
      width: 100%;
      margin-right: 0;
      display: flex;
      flex-direction: row;
    }

    .card-info {
      text-align: left;
      margin-top: 24px;
    }

    .card-text-subtitle {
      margin-top: 8px;
    }
  }

  .card {
    flex-direction: column;
    margin-bottom: 0;

    .card-img {
      width: 100%;
      margin-right: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .card-info {
      text-align: center;
      margin-top: 24px;
    }
  }

  .card.breakline {
    .card-info {
      .card-text-title {
        margin-top: 0;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .card-divider {
    .card-info {
      padding: 0 fn.spacing(20);
    }
  }
}
